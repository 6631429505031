$(function() {
	"use strict"; // Start of use strict



// header height
	var windowHeight = $(window).height() - 100;
	$('header').height(windowHeight);
	$(window).resize(function() {
		windowHeight = $(window).height() - 100;
		$('header').height(windowHeight);
	});
	// jQuery for page scrolling feature - requires jQuery Easing plugin
	$(document).on('click', 'a.page-scroll', function(event) {
		var $anchor = $(this);
		$('html, body').stop().animate({
			scrollTop: ($($anchor.attr('href')).offset().top - 100)
		}, 1250, 'easeInOutExpo');
		event.preventDefault();
	});
	// Highlight the top nav as scrolling occurs
	$('body').scrollspy({
		target: '.navbar-fixed-top',
		offset: 100
	});
	// Closes the Responsive Menu on Menu Item Click
	$('.navbar-collapse ul li a').click(function() {
		$('.navbar-toggle:visible').click();
	});
	// Offset for Main Navigation
	$('#mainNav').affix({
		offset: {
			top: 100
		}
	});
	// Initialize and Configure Magnific Popup Lightbox Plugin
	$('.popup-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: caricamentoImmagine,
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
		}
	});

	// go to anchor
	var url = $(location).attr('href');
	if (url.indexOf('#') !== -1) {
		var hash = url.substring(url.indexOf("#"));
		if (hash.indexOf('?') !== -1) {
			hash = hash.split('?');
			hash = hash[0];
		}
		var scroll = $(hash).offset().top - 100;
		$('html, body').animate({
			scrollTop: scroll
		}, 500
				);
	}
});
