$(function() {
	if (getCookie("cookieWarning") !== '1') {
		var cookieLink = {
			it: 'https://parcobotanicogambarogno.ch/legal-notice.php?lang=it',
			fr: 'https://parcobotanicogambarogno.ch/legal-notice.php?lang=fr',
			de: 'https://parcobotanicogambarogno.ch/legal-notice.php?lang=de',
			en: 'https://parcobotanicogambarogno.ch/legal-notice.php?lang=en'
		};
		var cookieText = {
			it: '<p>Questo sito utilizza cookie per le proprie funzionalità e per il miglioramento dei propri servizi. Continuando a navigare sul sito date il vostro consenso all\'utilizzo di cookie. Per saperne di più su come impostare le vostre preferenze, vi invitiamo a consultare <a href="' + cookieLink['it'] + '">politica sui cookie</a></p><a class="close-cookie-warning" href="javascript:void(0)">x</a>',
			fr: '<p>Ce site utilise des cookies pour les propres fonctionnalités et pour l\'amélioration de ces services. En continuant à parcourir ce site, vous donnez votre accord pour l\'utilisation des cookies.Pour en savoir plus sur la manière de définir vos préférences personnelles, veuillez lire notre <a href = "' + cookieLink['fr'] + '"> politique en matière de confidentialité et de cookies </a></p><a class="close-cookie-warning" href="javascript:void(0)">x</a>',
			de: '<p>Diese Webseite nutzt Cookies zur Unterstützung von Funktionalitäten und für die Verbesserung seiner Dienste. Mit dem Browsing dieser Webseite erklären Sie sich damit einverstanden, dass Cookies verwendet werden. Für weitere Informationen zur Festlegung Ihrer eigenen Präferenzen lesen Sie bitte unsere <a href="' + cookieLink['de'] + '">Cookie Policy</a></p><a class="close-cookie-warning" href="javascript:void(0)">x</a>',
			en: '<p>This site uses cookies for their functionality and for the improvement of its services. By continuing to browse this site, you give consent for cookies to be used. To find out more how to set your own preferences please read our <a href="' + cookieLink['en'] + '">Cookie Policy</a></p><a class="close-cookie-warning" href="javascript:void(0)">x</a>'
		};
		$('body').append('<div class="cookie-warning">' + cookieText[language] + '</div>');
		$('.cookie-warning').css({
			'font-family': 'Arial',
			'padding': '10px 50px 10px 10px',
			'position': 'fixed',
			'bottom': '0',
			'left': '0',
			'right': '0',
			'z-index': '10000',
			'background': '#eee'
		});
		$('.cookie-warning p').css({
			'margin': '0',
			'padding': '0',
			'font-size': '10px',
			'text-align': 'left',
			'color': '#333',
			'font-weight': 'normal'
		});
		$('.close-cookie-warning').css({
			'position': 'absolute',
			'top': '5px',
			'right': '10px',
			'color': '#000',
			'font-size': '18px',
			'font-weight': 'bold'
		});
		var now = new Date();
		var time = now.getTime();
		var expireTime = time + 1000 * 36000 * 7 * 52 * 100;
		now.setTime(expireTime);
		$('.close-cookie-warning').on('click', function() {
			$('.cookie-warning').remove();
			document.cookie = 'cookieWarning=1; expires=' + now.toGMTString() + '; path=/';
		});
	}
});
function getCookie(name)
{
	var re = new RegExp(name + "=([^;]+)");
	var value = re.exec(document.cookie);
	return (value != null) ? unescape(value[1]) : null;
}